import React from 'react';
import {
  Col, Divider, Row, Typography,
} from 'antd';
import { graphql } from 'gatsby';
import { BaseLayout } from '../components/layout/base/base-layout';
import { SEO } from '../components/layout/seo';
import { HelpCard } from '../components/cards/help-card/help-card';
import { LinkCard } from '../components/cards/link-card/link-card';

const { Title } = Typography;

const CollectionsPage = ({ data: { saleor: { collections: { edges: collections } } } }) => (
  <BaseLayout>
    <SEO title="Events & Supplier Collections" />
    <Title level={4} className="text-center">Events & Supplier Collections </Title>
    <Divider />
    <Row gutter={[32, 48]}>
      {collections.slice(0, Math.round(collections.length / 2))
        .map(({ node: collection }) => (
          <Col xs={24} md={12} lg={8} xxl={6} key={collection.id}>
            <LinkCard
              image={collection.backgroundImage?.url}
              overlay={collection.products.totalCount}
              tags={[`${collection.products.totalCount} Suppliers`]}
              title={collection.name}
              link={`/collection/${collection.slug}`}
            />
          </Col>
        ))}
      <Col xs={24} md={12} lg={8} xxl={6}>
        <HelpCard />
      </Col>
      {collections.slice(Math.round(collections.length / 2), collections.length)
        .map(({ node: collection }) => (
          <Col xs={24} md={12} lg={8} xxl={6} key={collection.id}>
            <LinkCard
              image={collection.backgroundImage?.url}
              title={collection.name}
              tags={[`${collection.products.totalCount} Suppliers`]}
              overlay={collection.products.totalCount}
              link={`/collection/${collection.slug}`}
            />
          </Col>
        ))}
    </Row>
  </BaseLayout>
);

export const query = graphql`
    query collections {
        saleor {
            collections(
                first: 100,
                filter: {published: PUBLISHED},
                sortBy: {direction: DESC, field: PUBLICATION_DATE}) {
                edges {
                    node {
                        id
                        name
                        slug
                        products {
                            totalCount
                        }
                        backgroundImage(size: 500) {
                            url
                        }
                    }
                }
            }
        }
    }
`;

export default CollectionsPage;
