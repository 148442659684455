import { Avatar, Button, Card } from 'antd';
import React, { useState } from 'react';
import css from './help-card.module.less';
import { ContactModal } from '../contact-modal/contact-modal';

const { Meta } = Card;

export const HelpCard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <>
      <Card
        className={css.card}
        title="Need Help?"
        actions={[
          <Button size="large" onClick={() => setIsModalVisible(true)}>Contact Us</Button>,
        ]}
      >
        <p className={css.flex}>
          <Avatar
            size={64}
            alt="Jason Greenman"
            src="https://d2rt6rm1u7sth6.cloudfront.net/team/AKOMMO_SESION_CORPORATIVA-76_1.jpg"
          />
          <Avatar
            size={64}
            alt="Julie Boulanger"
            src="https://d2rt6rm1u7sth6.cloudfront.net/team/small/AKOMMO_SESION_CORPORATIVA-4_1.jpg"
          />
          <Avatar
            size={64}
            alt="Richard Plane"
            src="https://d2rt6rm1u7sth6.cloudfront.net/team/small/AKOMMO_SESION_CORPORATIVA_1.jpg"
          />
        </p>
        <Meta
          title="Selecting suppliers can be tough."
          description="We can help you make the right decision."
        />
      </Card>
      <ContactModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
    </>
  );
};
